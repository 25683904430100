import React from 'react';
import { graphql } from 'gatsby';

import Section from '../components/Section/Section';
import SEO from '../components/seo';
import BlogGrid from '../components/BlogGrid/BlogGrid';

const CTFIndexPage = ({ data: { allFile, site: { siteMetadata } } }) => (
    <>
        <SEO title="Blog" description={siteMetadata.blog.description}/>
        <Section>
            <h1>CTF Write-Ups</h1>
            <BlogGrid posts={allFile.nodes.map(file => file.childMdx)} />
        </Section>
    </>
);

export default CTFIndexPage;

export const query = graphql`
query WriteUpIndex {
  site {
    siteMetadata {
      blog {
        description
      }
    }
  }
  allFile(
    sort: {
      fields: childMdx___frontmatter___date,
      order: DESC
    },
    filter: {
        sourceInstanceName: {eq: "write-ups"},
        childMdx: {internal: {type: {eq: "Mdx"}}}
    }
  ) {
    nodes {
      childMdx {
        id
        fields {
          slug
          path
        }
        timeToRead
        frontmatter {
          title
          description
          categories
          date(formatString: "MMMM DD, YYYY")
          featuredImage {
            childImageSharp {
              fluid(maxWidth: 450, maxHeight: 200, cropFocus: ATTENTION) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
}
`;
